import React from 'react';
import { Link } from 'react-router-dom';
import { SingleSliderItem } from '../../../../components/SingleSliderItem/SingleSliderItem';
import Slider from 'react-slick';
import internationalPublications from '../../../../fakeData/internationalPublications';
import nationalPublications from '../../../../fakeData/nationalPublications';
import acceptedPosters from '../../../../fakeData/acceptedPosters';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const DoctorDetailsArea = () => {
   const { t, i18n } = useTranslation();
   const settings = {
      autoplay: true,
      autoplaySpeed: 5000,
      dots: true,
      arrows: true,
      slidesToShow: 4,
      slidesToScroll: 2,
      responsive: [
         {
            breakpoint: 1200,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
               infinite: true,
            }
         },
         {
            breakpoint: 991,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false
            }
         },
      ]

   };

   return (
      <>
         <div className="doctor-details-area pt-50 pb-70">
            <Helmet>
               <title>{'ANKARA | Doç Dr. Ebru Karagün  | Dolgu'}</title>
               <meta name='description' content='Dolgu, yüz askılama yöntemleri, yüz ve saç mezoterapisi, glutatyon, ozon tedavisi, Scarlet X ve diğer cihaz işlemleri konusunda uzman' />
            </Helmet>
            <div className="container">
               <div className="row">
                  <div className="col-xl-5 col-lg-4">
                     <div className="service-widget mb-80">
                        <div className="team-wrapper team-box-2 team-box-3 text-center mb-30">
                           <div className="team-thumb">
                              <img src="/img/media/doc-dr-ebru-karagun-fotoyaslanma.jpg" alt="" />
                           </div>
                           <div className="team-member-info mt-35 mb-35">
                              <h3 className="f-500 text-up-case letter-spacing pink-color" style={{ fontWeight: "600" }}>{t('resumePage.docDr')}</h3>
                              <h3><Link to={i18n.language === 'en' ? "/en/resume" : "/ozgecmis"}>Ebru Karagün</Link></h3>

                           </div>
                           <div className="team-social-profile">
                              <ul>
                                 <li><a href="https://www.facebook.com/doc.drebrukaragun" target='_blank' rel="noreferrer"><i className="fab fa-facebook-f"></i></a></li>
                                 <li><a href="https://www.youtube.com/@ezdermdoc.dr.ebrukaragun1982" target='_blank' rel="noreferrer"><i className="fab fa-youtube"></i></a></li>
                                 <li><a href="https://www.instagram.com/doc.drebrukaragun/" target='_blank' rel="noreferrer"><i className="fab fa-instagram"></i></a></li>
                              </ul>
                           </div>
                        </div>
                     </div>

                     <div className="service-widget mb-50">
                        <div className="widget-title-box mb-30">
                           <h3 className="widget-title">{t('resumePage.languages')}</h3>
                        </div>
                        <div className="doctor-detials-lan">
                           <ul>
                              <li><a href="#">en</a></li>
                              <li><a href="#">tr</a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-7 col-lg-8">
                     <article className="doctor-details-box">
                        <div className="section-title pos-rel mb-25" style={{ zIndex: "-1" }}>
                           <div className="section-icon">
                              <img className="section-back-icon back-icon-left" src="/img/section/section-back-icon-sky.png"
                                 alt="" />
                           </div>
                           <div className="section-text pos-rel">
                              <h5 className="green-color text-up-case">{t('resumePage.introducingMyself')}</h5>
                           </div>
                           <div className="section-line pos-rel">
                              <img src="/img/shape/section-title-line.png" alt="" />
                           </div>
                        </div>
                        <div className="service-details-text mb-20">
                           <p>{t('resumePage.resumeDesc')}</p>
                        </div>

                        <div className="service-widget mb-20">
                           <div className="widget-title-box mb-30">
                              <h3 className="widget-title">{t('resumePage.myExperience')}</h3>
                           </div>
                           <div className="more-service-list">
                              <ul>
                                 <li>
                                    <Link to={i18n.language === 'en' ? "/en/resume" : "/ozgecmis"}>
                                       <div className="more-service-icon"><img src="/img/icon/teacher.png" alt="" /></div>
                                       <div className="more-service-title doctor-details-title">{t('resumePage.experience1Title')} <span> {t('resumePage.experience1Desc')}</span></div>
                                    </Link>
                                 </li>
                                 <li>
                                    <Link to={i18n.language === 'en' ? "/en/resume" : "/ozgecmis"}>
                                       <div className="more-service-icon"><img src="/img/icon/doctor-patient.png" alt="" /></div>
                                       <div className="more-service-title doctor-details-title">{t('resumePage.experience2Title')} <span> {t('resumePage.experience2Desc')}</span></div>
                                    </Link>
                                 </li>
                                 <li>
                                    <Link to={i18n.language === 'en' ? "/en/resume" : "/ozgecmis"}>
                                       <div className="more-service-icon"><img src="/img/icon/doctor-patient.png" alt="" /></div>
                                       <div className="more-service-title doctor-details-title">{t('resumePage.experience3Title')} <span>{t('resumePage.experience3Desc')}</span></div>
                                    </Link>
                                 </li>
                                 <li>
                                    <Link to={i18n.language === 'en' ? "/en/resume" : "/ozgecmis"}>
                                       <div className="more-service-icon"><img src="/img/icon/doctor.png" alt="" /></div>
                                       <div className="more-service-title doctor-details-title">{t('resumePage.experience4Title')} <span>{t('resumePage.experience4Desc')}</span></div>
                                    </Link>
                                 </li>
                                 <li>
                                    <Link to={i18n.language === 'en' ? "/en/resume" : "/ozgecmis"}>
                                       <div className="more-service-icon"><img src="/img/icon/diagram.png" alt="" /></div>
                                       <div className="more-service-title doctor-details-title">{t('resumePage.experience5Title')} <span>{t('resumePage.experience5Desc')}</span></div>
                                    </Link>
                                 </li>
                                 <li>
                                    <Link to={i18n.language === 'en' ? "/en/resume" : "/ozgecmis"}>
                                       <div className="more-service-icon"><img src="/img/icon/graduation-hat-diploma.png" alt="" /></div>
                                       <div className="more-service-title doctor-details-title">{t('resumePage.experience6Title')} <span>{t('resumePage.experience6Desc')}</span></div>
                                    </Link>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </article>
                  </div>
                  <div className="section-title pos-rel mb-25">
                     <div className="section-text pos-rel">
                        <h3>{t('resumePage.internationalPublications')}</h3>
                     </div>
                     <div className="section-line pos-rel">
                        <img src="/img/shape/section-title-line.png" alt="" />
                     </div>
                  </div>
                  <div className="service-details-text mb-80 internationalPublish">

                     <Slider {...settings}>
                        {internationalPublications.map(e => {
                           return <SingleSliderItem description={e} />
                        })}

                     </Slider>
                  </div>
                  <div className="section-title pos-rel mb-25">
                     <div className="section-text pos-rel">
                        <h3>{t('resumePage.nationalPublications')}</h3>
                     </div>
                     <div className="section-line pos-rel">
                        <img src="/img/shape/section-title-line.png" alt="" />
                     </div>
                  </div>
                  <div className="service-details-text mb-80 internationalPublish">

                     <Slider {...settings}>
                        {nationalPublications.map(e => {
                           return <SingleSliderItem description={e} />
                        })}

                     </Slider>
                  </div>
                  <div className="section-title pos-rel mb-25">
                     <div className="section-text pos-rel">
                        <h3>{t('resumePage.postersAccepted')}</h3>
                     </div>
                     <div className="section-line pos-rel">
                        <img src="/img/shape/section-title-line.png" alt="" />
                     </div>
                  </div>
                  <div className="service-details-text mb-80 internationalPublish">

                     <Slider {...settings}>
                        {acceptedPosters.map(e => {
                           return <SingleSliderItem description={e} />
                        })}

                     </Slider>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default DoctorDetailsArea;