import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
   const { t, i18n } = useTranslation();
   return (
      <>
         <footer>
            <div className="footer-top theme-bg pt-100">
               <div className="container">
                  <div className="footer-mid pb-60">
                     <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6">
                           <div className="mb-60">
                              <div className="row align-items-center">
                                 <div className="col-xl-10 col-lg-10 col-md-10">
                                    <div className="footer-logo-2 mb-55" style={{ display: "flex", justifyContent: "center" }}>
                                    </div>
                                    <div>
                                       <h3 className='text-center white-color great-vibes'>{t('mainPage.drTitle')}</h3>
                                       <h4 className='text-center white-color great-vibes'>{t('mainPage.dermatologySpecialist')}</h4>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                           <div className="footer-widget mb-40">
                              <div className="footer-title">
                                 <h3>{t('headerTwo.contact')}</h3>
                              </div>
                              <div className="footer-contact-info-2">
                                 <div className="f-contact-info-box fix mb-20">
                                    <div className="footer-co-icon f-left">
                                       <img src="/img/icon/footer-co-icon-1.png" alt="" />
                                    </div>
                                    <div className="footer-co-content">
                                       <a href="tel:+905332691271">
                                          <h4 >+90 533 269 12 71</h4>
                                       </a>
                                       <br />
                                       <a href="tel:+903125574646">
                                          <h4>+90 312 557 46 46</h4>
                                       </a>
                                       <br />
                                    </div>
                                 </div>
                                 <div className="f-contact-info-box fix mb-20">
                                    <div className="footer-co-icon f-left">
                                       <img src="/img/icon/footer-co-icon-2.png" alt="" />
                                    </div>
                                    <div className="footer-co-content">
                                       <span>{t('footerTwo.question')}</span>
                                       <a href="mailto:info@drebrukaragun.com">
                                          <h5 className='white-color'>info@drebrukaragun.com</h5>
                                       </a>
                                    </div>
                                 </div>
                                 <div className="f-contact-info-box fix">
                                    <div className="footer-co-icon f-left">
                                       <img src="/img/icon/footer-co-icon-2.png" alt="" />
                                    </div>
                                    <div className="footer-co-content">
                                       <span>{t('footerTwo.socialMedia')}</span>
                                       <ul>
                                          <li style={{ marginInline: "10px" }}><a href="https://www.facebook.com/doc.drebrukaragun" target='_blank' rel="noreferrer"><i className="fab fa-facebook-f"></i></a></li>
                                          <li style={{ marginInline: "10px" }}><a href="https://www.youtube.com/@ezdermdoc.dr.ebrukaragun1982" target='_blank' rel="noreferrer"><i className="fab fa-youtube"></i></a></li>
                                          <li style={{ marginInline: "10px" }}><a href="https://www.instagram.com/doc.drebrukaragun/" target='_blank' rel="noreferrer"><i className="fab fa-instagram"></i></a></li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                           <div className="footer-widget mb-40">
                              <div className="footer-title">
                                 <h3>{t('footerTwo.ourServices')}</h3>
                              </div>
                              <div className="footer-menu footer-menu fix">
                                 <div>
                                    <Link to={i18n.language === 'en' ? "/en/applications/filler-applications" : "/uygulamalar/dolgu-uygulamalari"}>
                                       <p className='white-color mb-20' style={{ fontSize: "18px" }}>{t('headerTwo.fillingApplications')}</p>
                                    </Link>
                                    <Link to={i18n.language === 'en' ? "/en/applications/thread-applications" : "/uygulamalar/iple-olan-uygulamalar"}>
                                       <p className='white-color mb-20' style={{ fontSize: "18px" }}>{t('headerTwo.faceLiftingApplications')}</p>
                                    </Link>
                                    <Link to={i18n.language === 'en' ? "/en/applications/device-procedures" : "/uygulamalar/cihaz-islemleri"}>
                                       <p className='white-color mb-20' style={{ fontSize: "18px" }}>{t('headerTwo.deviceOperations')}</p>
                                    </Link>
                                    <Link to={i18n.language === 'en' ? "/en/applications/mesotherapy-applications" : "/uygulamalar/mezoterapi-uygulamalari"}>
                                       <p className='white-color mb-20' style={{ fontSize: "18px" }}>{t('headerTwo.mesotherapyApplications')}</p>
                                    </Link>
                                    <Link to={i18n.language === 'en' ? "/en/applications/healthy-living" : "/uygulamalar/saglikli-yasam"}>
                                       <p className='white-color' style={{ fontSize: "18px" }}>{t('headerTwo.healthyLiving')}</p>
                                    </Link>
                                 </div>
                              </div>
                           </div>
                           <p className='footer-p'>{t('footerTwo.lastUpdatedDateText')}: {t('footerTwo.lastUpdatedDate')}</p>
                        </div>
                     </div>
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>
                     {t('mainPage.developedByMobiversite')}
                  </div>
                  <div className="footer-bottom-0">
                     <div className="row">
                        <div className="col-xl-12 mb-20">
                           <div className="footer-copyright-area text-center">
                              <p className="white-color">{t('footerTwo.kvkk')}</p>
                              <a className='white-color' href={i18n.language === 'en' ? "/pdf/Personal-Data-Protection-Law.pdf" : "/pdf/KISISEL-VERILERIN-KORUNMASI-VE-ISLENMESI-ILISKIN.pdf"}> {t('mainPage.kvkkPdf')} </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
      </>
   );
};

export default Footer;