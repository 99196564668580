import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ show, handleClose }) => {
   const { t, i18n } = useTranslation();
   const navigate = useNavigate();
   const Applications = <NavLink to="#">{t('headerTwo.applications')} </NavLink>
   const CosmeticHead = <NavLink to="#">{t('headerTwo.cosmeticDermatology')}</NavLink>;

   const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
   };

   return (
      <>

         <div >
            <Offcanvas show={show} onHide={handleClose} placement='end' className='side__bar'>
               <Offcanvas.Header closeButton>
                  {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
               </Offcanvas.Header>
               <Offcanvas.Body>

                  <div style={{ marginBottom: "15px" }}>
                     <NavLink to={i18n.language === 'en' ? "/en/" : "/"}>{t('headerTwo.homePage')}</NavLink>
                  </div>

                  <div style={{ marginBottom: "15px" }}>
                     <NavLink to={i18n.language === 'en' ? "/en/resume" : "/ozgecmis"}>{t('headerTwo.resume')}</NavLink>
                  </div>

                  <Collapsible trigger={Applications} triggerTagName="div"
                     triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                     <ul className="sidebar_sub_menu text-white mt-3">
                        <li><NavLink to={i18n.language === 'en' ? "/en/applications/medical-applications" : "/uygulamalar/medikal-uygulamalar"}>{t('headerTwo.medicalDermatology')}</NavLink></li>
                        <li>
                           <Collapsible trigger={CosmeticHead} triggerTagName="div"
                              triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                              <ul className="sidebar_sub_menu text-white mt-3">
                                 <li><NavLink to={i18n.language === 'en' ? "/en/applications/filler-applications" : "/uygulamalar/dolgu-uygulamalari"}>{t('headerTwo.fillingApplications')}</NavLink></li>
                                 <li><NavLink to={i18n.language === 'en' ? "/en/applications/thread-applications" : "/uygulamalar/iple-olan-uygulamalar"}>{t('headerTwo.faceLiftingApplications')}</NavLink></li>
                                 <li><NavLink to={i18n.language === 'en' ? "/en/applications/mesotherapy-applications" : "/uygulamalar/mezoterapi-uygulamalari"}>{t('headerTwo.mesotherapyApplications')}</NavLink></li>
                                 <li><NavLink to={i18n.language === 'en' ? "/en/applications/device-procedures" : "/uygulamalar/cihaz-islemleri"}>{t('headerTwo.deviceOperations')}</NavLink></li>
                              </ul>
                           </Collapsible>
                        </li>
                        <li><NavLink to={i18n.language === 'en' ? "/en/applications/healthy-living" : "/uygulamalar/saglikli-yasam"}>{t('headerTwo.healthyLiving')}</NavLink></li>
                     </ul>
                  </Collapsible>

                  <div style={{ marginBottom: "15px" }}>
                     <NavLink to={i18n.language === 'en' ? "/en/blogs" : "/blogs"}>{t('headerTwo.blog')}</NavLink>
                  </div>

                  <div style={{ marginBottom: "15px" }}>
                     <NavLink to={i18n.language === 'en' ? "/en/media" : "/medyada-biz"}>{t('headerTwo.weInTheMedia')}</NavLink>
                  </div>

                  <div style={{ marginBottom: "15px" }}>
                     <NavLink to={i18n.language === 'en' ? "/en/contact" : "/contact"}>{t('headerTwo.contact')}</NavLink>
                  </div>

                  <div>
                     <div className="header-lang pos-rel">
                        <div className="lang-icon">
                           {i18n.language === 'en' ? <img src="/img/icon/lang.png" alt="" /> : <img src="/img/icon/turkey.png" alt="" />}
                           {i18n.language === 'tr' ? <span className='white-color'>TR<i className="fas fa-angle-down white-color"></i></span> :
                              <span className='white-color'>EN<i className="fas fa-angle-down white-color"></i></span>}
                        </div>
                        <ul className="header-lang-list" style={{ width: '60px', left: 0 }}>
                           {i18n.language === 'en' ? <li><a href='#' onClick={() => {
                              changeLanguage('tr')

                              const newPath = window.location.pathname.split('/')
                              if (newPath[1] === 'en') {
                                 navigate('/' + window.location.pathname.split('/en/').slice(-1)[0])
                              }
                           }}>TR</a></li> : null}
                           {i18n.language === 'tr' ? <li><a href='#' onClick={() => {
                              changeLanguage('en')

                              const newPath = window.location.pathname.split('/')
                              if (newPath[1] !== 'en') {
                                 navigate('/en' + window.location.pathname)
                              }
                           }}>EN</a></li> : null}
                        </ul>
                     </div>
                  </div>
               </Offcanvas.Body>
            </Offcanvas>
         </div>
      </>
   );
};

export default Sidebar;