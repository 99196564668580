const TRANSLATION_EN = {
  headerTwo: {
    homePage: 'Home',
    resume: 'Resume',
    applications: 'Applications',
    blog: 'Blog',
    weInTheMedia: 'Media',
    contact: 'Contact',
    contactUs: 'Contact us',
    medicalDermatology: 'Medical Dermatology',
    cosmeticDermatology: 'Cosmetic Dermatology',
    fillingApplications: 'Filling Applications',
    faceLiftingApplications: 'Face Lift Applications',
    mesotherapyApplications: 'Mesotherapy Applications',
    deviceOperations: 'Device Operations',
    healthyLiving: 'Healthy lifestyle',
    appointment: 'Make an Appointment'
  },
  mainPage: {
    drTitle: 'Assoc. Prof. Ebru Karagün',
    dermatologySpecialist: 'Dermatology Specialist',
    mainHeader: 'Dermatology, Cosmetics & Wellness',
    mesotherapy: 'Mesotherapy',
    fillingApplications: 'Filling Applications',
    faceLifting: 'Face Lift',
    healthyLiving: 'Healthy lifestyle',
    deviceOperations: 'Device Operations',
    medicalTreatments: 'Medical Treatments',
    mesotherapyDesc: 'Antioxidant substances in mesotherapy products reverse aging.',
    fillingApplicationsDesc: 'Filling applications are the applications that are in the second place among cosmetic applications.',
    faceLiftingDesc: 'It is a procedure based on placing specially produced threads compatible with human tissue under the skin with the help of a thin needle.',
    healthyLivingDesc: 'Although the definition of healthy life is different for many people, it can generally be defined as having habits that are beneficial to our body and soul.',
    deviceOperationsDesc: 'In recent years, device procedures have become increasingly common and safe in order to renew and rejuvenate the skin.',
    medicalTreatmentsDesc: 'Medical treatment is interventions with medical methods and drugs used in the diagnosis and treatment of diseases or disorders.',
    welcome: 'Welcome',
    aboutDesc1: 'The desire to look beautiful, well-groomed and attractive is a social behavior as old as human history, dating back to the Egyptian Queen Cleopatra.',
    aboutDesc2: 'Cosmetics is derived from the Greek word kosmetikos, meaning "master of adornment".',
    aboutDesc3: 'As Coco Chanel said, who doesn"t want to come to terms with getting old',
    aboutDesc4: 'A woman is the age she deserves.',
    aboutDesc5: '"Look good……Feel Better"',
    aboutDesc6: 'We provide service in Moment Beştepe Plaza with all the dermo-cosmetic procedures and complementary treatments for healthy living in our clinic.',
    readMore: 'More',
    readMoreBlog: 'Read more',
    aboutMe: 'About me',
    missionDesc: 'After graduating from Ondokuz Mayıs University, I completed my specialty training in Ankara Dışkapı Yıldırım Beyazıt Training and Research Hospital, Skin and Venereal Diseases Clinic. After completing my compulsory service at Ağrı State Hospital, I worked at Istanbul Medipol University Faculty of Medicine and Düzce University Faculty of Medicine with the title of Assistant Professor and in 2021 I received the title of Associate Professor. I have been leaving Ankara Liv Hospital, where I have been working since 2021, and have been continuing in my own clinic since April 2022.',
    missionSubDesc1: 'I have more than 50 articles published in national and international journals.',
    missionSubDesc2: 'My special interests are; diagnosis and treatment of all dermatological diseases, dermocosmetic procedures and dermatoscopy.',
    blogTitle: 'What We Have Prepared For You',
    blog1Title: 'Acne - Treatment of Acne Scars',
    blog2Title: '7 Best Antioxidants for Your Skin',
    blog3Title: 'Sunburn',
    blog4Title: 'What is Collagen?',
    blog5Title: 'Why Is Hair White?',
    blog6Title: 'Male Pattern Hair Loss',
    blog1Date: 'Fri Nov 25 2022',
    blog2Date: 'Fri Mar 03 2023',
    blog3Date: 'Fri Jul 14 2023',
    blog4Date: 'Tue Mar 28 2023',
    appoinmentSubHeader: 'Everything you need to feel healthy and beautiful',
    appoinmentHeader: 'The First Consultation in Cosmetic Applications is Free.',
    formName: 'Your name',
    formMail: 'Email',
    formSubject: 'Subject',
    formPhone: 'Phone number',
    formMessage: 'Your Message (Optıonal)',
    formSend: 'Send',
    mediaTitle: 'To Inform You',
    media1Title: 'Assoc. Prof. Ebru Karagün - How Do We Maintain the Quality of Our Skin?',
    media1Desc: 'How do we maintain the quality of our skin? Your skin is your body"s window to reveal the stories of your life. From acne in adolescence, ...',
    media1Date: 'Fri Jul 14 2023',
    media2Title: 'Assoc. Prof. Ebru Karagün - Look Good, Feel Good!',
    media2Desc: 'Look Good, Feel Good! Face rope suspension, almond eye, hair and face mesotherapy, glutathione, ozone application treatments, gold...',
    media2Date: 'Fri Jul 14 2023',
    media3Title: 'Assoc. Prof. Ebru Karagün - Photoaging',
    media3Desc: 'Assoc. Prof. Ebru Karagün Photoaging Explaining the causes and consequences of photoaging by addressing the factors affecting skin aging, Assoc. Prof. Ebru...',
    media3Date: 'Tue Mar 28 2023',
    media4Title: 'Assoc. Prof. Ebru Karagün - Stretch Marks',
    media4Desc: 'Assoc. Prof. Ebru Karagün Skin Cracked Dermatology Assoc. Prof. Ebru Karagün dealt with the formation of skin cracks.',
    media4Date: 'Tue Mar 28 2023',
    kvkkPdf: 'Personal Data Protection Law',
    developedByMobiversite: 'Developed by Mobiversite Yazılım Bilişim Reklam ve Danışmanlık Hizmetleri Sanayi Tic. Ltd. Şti.',
  },
  footerTwo: {
    question: 'Have a question?',
    socialMedia: 'SOCIAL MEDIA',
    ourServices: 'Our services',
    lastUpdatedDateText: 'Last Update Date',
    lastUpdatedDate: '25.05.2024',
    kvkk: 'This site has been prepared to inform people and does not provide health services. The information on the site should not be used in the diagnosis and treatment of patients in any way. All responsibility arising from the use of the site content for diagnostic and therapeutic purposes belongs to the visitor. Visitors should consult a doctor for any information or health problems on the site. Visitors to this site are deemed to have accepted these warnings. This site does not aim to advertise, diagnose and treat, it is only for informing visitors.'
  },
  resumePage: {
    docDr: 'Assoc. Prof.',
    languages: 'Languages',
    introducingMyself: 'I am introducing myself',
    resumeDesc: 'After graduating from Ondokuz Mayıs University, I completed my specialty training in Ankara Dışkapı Yıldırım Beyazıt Training and Research Hospital, Skin and Venereal Diseases Clinic. After completing my compulsory service at Ağrı State Hospital, I worked at Istanbul Medipol University Faculty of Medicine and Düzce University Faculty of Medicine with the title of Assistant Professor and in 2021 I received the title of Associate Professor. I have been leaving Ankara Liv Hospital, where I have been working since 2021, and have been continuing in my own clinic since April 2022. At the same time, working as a visiting professor at the Istinye University Faculty of Medicine, Skin and Venereal Diseases Clinic. My special interests include dermocosmetic applications (mesotherapy, fillers, face lifting methods), ozone therapy, acne, vitiligo, skin cancers, dermoscopy. I have more than 50 articles published in national and international journals.',
    myExperience: 'My Experiences',
    experience1Title: 'Associate Professor Doctor Title',
    experience1Desc: 'Title Receipt Date: 2021',
    experience2Title: 'Assistant Professor Doctor Title',
    experience2Desc: 'Istanbul Medipol University Faculty of Medicine',
    experience3Title: 'Assistant Professor Doctor Title',
    experience3Desc: 'Duzce University Faculty of Medicine',
    experience4Title: 'Compulsory service',
    experience4Desc: 'Agri State Hospital',
    experience5Title: 'Specialization Training',
    experience5Desc: 'Ankara Diskapi Yildirim Beyazit Training and Research Hospital',
    experience6Title: 'Medical School Bachelor"s Degree',
    experience6Desc: 'Ondokuz Mayıs University',
    internationalPublications: 'International Publications',
    nationalPublications: 'National Publications',
    postersAccepted: 'Accepted Posters at International and National Congresses - Oral Presentations'
  },
  applicationPage: { header: 'Applications' },
  appDetailPage: {
    popularApplications: 'Popular Apps',
    popularApp1Title: 'Filling Application',
    popularApp1Desc: 'Nowadays, non-surgical procedures related to facial aesthetics are much more preferred.',
    popularApp2Title: 'Scarlet X',
    popularApp2Desc: 'With specially designed micro-needles, it stimulates the subcutaneous skin with radio frequency waves and initiates the skin rejuvenation process.',
    popularApp3Title: 'Endolift',
    popularApp3Desc: 'It is an effective skin resurfacing treatment for removing superficial blemishes on the skin.',
    detail: 'Details',
  },
  blogsPage: { subHeader: 'Blog Posts', header: 'Latest articles' },
  mediaDetailsPage: {
    newsSources: 'News Sources',
    clickForPdf: 'Click for pdf...',
    clickForNew: 'Click to see the news...',
    mostRead: 'Most Read',
    blog1Title: 'Sunburn',
    blog1Desc: 'A sunburn is painful skin that feels hot to the touch.',
    blog2Title: '7 Best Antidoxants for Your Skin',
    blog2Desc: 'Antioxidants work by reversing or preventing the oxidative stress that causes cells to age.',
    blog3Title: 'Male Pattern Hair Loss',
    blog3Desc: 'Male Pattern Hair Loss: It is the thinning of the hair follicle with the effect of hormones in genetically predisposed individuals.'
  },
  contactPage: {
    email: 'Email',
    address: 'Address',
    phone: 'Telephone',
    formSubHeader: 'For Everything You Think About',
    formHeader: 'Contact Us',
    swalTitle: 'Thank you for contacting us.',
    swalText: 'Our team will get back to you as soon as possible.'
  },
  appointmentPage: {
    weekendsErrorTitle: 'No appointments on weekends!',
    weekendsErrorDesc: 'Please select a weekday date.',
    emailError: 'You must enter your email address',
    nameError: 'You must enter your full name',
    phoneError: 'You must enter your phone number',
    successful: 'Successful!',
    appointmentRequest: 'Your appointment request has been received.',
    error: 'Error!',
    appointmentError: 'There was a problem with your appointment request. Please contact us via our phone!',
    tooManyRequest: 'You have exceeded the maximum limit of sending an appointment request within 1 hour!',
    makeAnAppointment: 'Make an Appointment',
    onlineAppointment: 'Online Appointment System',
    selectAppointmentTime: 'Select Appointment Time...',
    selectApplication: 'Select Application...',
    medicalDermatology: 'Medical Dermatology',
    cosmeticDermatology: 'Cosmetic Dermatology',
    wellness: 'Wellness',
    selectDate: 'Select Date',
    selectTime: 'Select Time'
  },
  notFoundPage: {
    header: 'Not Found Page!',
    description: 'Oops! The page you are looking for does not exist. It may have been moved or deleted.',
    mainPage: 'HOMEPAGE',
    weAreHereForYou: 'We are here for you.'
  }
}

export default TRANSLATION_EN;

