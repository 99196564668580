import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../../../components/Shared/Sidebar/Sidebar';
import useGlobalContext from '../../../../hooks/useGlobalContext';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

const HomeHeader = () => {
   const { t, i18n } = useTranslation();
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const { stickyMenu } = useGlobalContext();
   const navigate = useNavigate();
   const location = useLocation();

   const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);

      if (location.pathname.split('/')[1].includes('blogs')) {
         navigate('/blogs')
      }
   };

   return (
      <>
         <header>
            <div className="top-bar d-none d-md-block">
               <div className="container">
                  <div className="row d-flex align-items-center">
                     <div className="col-xl-9 col-lg-9 col-md-9">
                        <div className="header-info">
                           <a href="tel:+905332691271"><span><i className="fas fa-phone"></i> +90 533 269 12 71</span></a>
                           <a href="tel:+903125574646"> <span><i className="fas fa-phone"></i> +90 312 557 46 46</span></a>
                           <a href="mailto:info@drebrukaragun.com"> <span><i className="fas fa-envelope"></i> info@drebrukaragun.com</span></a>
                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-3 col-md-3">
                        <div className="header-top-right-btn f-right">
                           <Link to={i18n.language === 'en' ? "/en/appointment" : "/randevu-al"} className="primary_btn">{t('headerTwo.appointment')}</Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className={stickyMenu ? "header-menu-area sticky_menu" : "header-menu-area"}>
               <div className="container menu_wrapper">
                  <div className="row align-items-center">
                     <div className="col-xl-2 col-lg-2 col-md-6 col-6 d-flex align-items-center">
                        <div className="logo pos-rel">
                           <Link to={i18n.language === 'en' ? "/en/" : "/"}>
                              <div className='custom-logo'>
                                 {t('mainPage.drTitle')}
                              </div>
                           </Link>
                        </div>
                     </div>
                     <div className="col-xl-8 col-lg-8 col-md-6 col-6">
                        <div className="header-right f-right">
                           <div className="header-lang f-right pos-rel d-none d-lg-block">
                              <div className="lang-icon">
                                 {i18n.language === 'en' ? <img src="/img/icon/lang.png" alt="" /> : <img src="/img/icon/turkey.png" alt="" />}
                                 {i18n.language === 'tr' ? <span className='white-color'>TR<i className="fas fa-angle-down white-color"></i></span> :
                                    <span className='white-color'>EN<i className="fas fa-angle-down white-color"></i></span>}
                              </div>
                              <ul className="header-lang-list">
                                 {i18n.language === 'en' ? <li><a href='#' onClick={() => {
                                    changeLanguage('tr');

                                    const newPath = window.location.pathname.split('/')
                                    if (newPath[1] === 'en') {
                                       navigate('/' + window.location.pathname.split('/en/').slice(-1)[0])
                                    }
                                 }}>TR</a></li> : <li><a href='#' onClick={() => {
                                    changeLanguage('en');
                                    const newPath = window.location.pathname.split('/')
                                    if (newPath[1] !== 'en') {
                                       navigate('/en' + window.location.pathname)
                                    }

                                 }}>EN</a></li>}
                              </ul>
                           </div>
                           <div className="header-social-icons f-right d-none d-xl-block">
                              <ul>
                                 <li><a href="https://www.facebook.com/doc.drebrukaragun" target='_blank' rel="noreferrer"><i className="fab fa-facebook-f"></i></a></li>
                                 <li><a href="https://www.youtube.com/@ezdermdoc.dr.ebrukaragun1982" target='_blank' rel="noreferrer"><i className="fab fa-youtube"></i></a></li>
                                 <li><a href="https://www.instagram.com/doc.drebrukaragun/" target='_blank' rel="noreferrer"><i className="fab fa-instagram"></i></a></li>
                              </ul>
                           </div>
                        </div>
                        <div className="header__menu">
                           <nav id="mobile-menu">
                              <ul>
                                 <li><Link to={i18n.language === 'en' ? "/en/" : "/"}>{t('headerTwo.homePage')}</Link>
                                 </li>
                                 <li><Link to={i18n.language === 'en' ? "/en/resume" : "/ozgecmis"}>{t('headerTwo.resume')}</Link>
                                 </li>
                                 <li><Link to="#">{t('headerTwo.applications')} +</Link>
                                    <ul className="submenu">
                                       <li><Link to={i18n.language === 'en' ? "/en/applications/medical-applications" : "/uygulamalar/medikal-uygulamalar"}>{t('headerTwo.medicalDermatology')}</Link></li>
                                       <li className='menu-item'><Link to="#">{t('headerTwo.cosmeticDermatology')} +</Link>
                                          <ul className="submenu" style={{ marginLeft: "100px" }}>

                                             <li>
                                                <Link to={i18n.language === 'en' ? "/en/applications/filler-applications" : "/uygulamalar/dolgu-uygulamalari"}>{t('headerTwo.fillingApplications')}</Link>
                                             </li>
                                             <li>
                                                <Link to={i18n.language === 'en' ? "/en/applications/thread-applications" : "/uygulamalar/iple-olan-uygulamalar"}>{t('headerTwo.faceLiftingApplications')}</Link>
                                             </li>
                                             <li>
                                                <Link to={i18n.language === 'en' ? "/en/applications/mesotherapy-applications" : "/uygulamalar/mezoterapi-uygulamalari"}>{t('headerTwo.mesotherapyApplications')}</Link>
                                             </li>
                                             <li>
                                                <Link to={i18n.language === 'en' ? "/en/applications/device-procedures" : "/uygulamalar/cihaz-islemleri"}>{t('headerTwo.deviceOperations')}</Link>
                                             </li>
                                          </ul>
                                       </li>
                                       <li><Link to={i18n.language === 'en' ? "/en/applications/healthy-living" : "/uygulamalar/saglikli-yasam"}>{t('headerTwo.healthyLiving')}</Link></li>
                                    </ul>
                                 </li>

                                 <li><Link to={i18n.language === 'en' ? "/en/blogs" : "/blogs"}>{t('headerTwo.blog')}</Link></li>
                                 <li><Link to={i18n.language === 'en' ? "/en/media" : "/medyada-biz"}>{t('headerTwo.weInTheMedia')}</Link></li>
                                 <li><a href={i18n.language === 'en' ? "/en/contact" : "/contact"}>{t('headerTwo.contact')}</a>
                                 </li>
                              </ul>
                           </nav>
                        </div>

                        <div className="side-menu-icon d-lg-none text-end" style={{ marginRight: "20px" }}>
                           <button onClick={handleShow} className="side-toggle border-0 bg-transparent">
                              <i className="fas fa-bars"></i> </button>
                        </div>
                     </div>
                     <div className="col-xl-2 col-lg-2 d-none d-lg-block d-xl-block">
                        <div>
                           <Link to={i18n.language === 'en' ? "/en/contact" : "/contact"} className="primary_btn btn-icon btn-icon-green"><span>
                              <a href={i18n.language === 'en' ? "/en/contact" : "/contact"}><i className="fas fa-envelope"></i></a></span>
                              {t('headerTwo.contactUs')}
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>

         <Sidebar show={show} handleClose={handleClose} />
      </>
   );
};

export default HomeHeader;