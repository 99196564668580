import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import 'moment/locale/tr';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useTranslation } from 'react-i18next';


const BookAppoinment = () => {
   const { t } = useTranslation();
   const currentDate = new Date();
   const currentHour = currentDate.getHours();
   const [bookingDate, setBookingDate] = useState(() => {
      const initialDate = new Date();
      if (currentHour >= 19) {
         initialDate.setDate(initialDate.getDate() + 1);
      }
      return initialDate;
   });
   const [bookingHour, setBookingHour] = useState('randevu saatini seçiniz...')
   const [name, setName] = useState('');
   const [phone, setPhone] = useState('');
   const [mail, setMail] = useState('');
   const [message, setMessage] = useState('');
   const [application, setApplication] = useState('Cilt Bakımı');
   const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
   const [formError, setFromError] = useState('');
   const timeSlots = [
      '09:00-10:00',
      '10:00-11:00',
      '11:00-12:00',
      '12:00-13:00',
      '13:00-14:00',
      '14:00-15:00',
      '15:00-16:00',
      '16:00-17:00',
      '17:00-18:00',
      '18:00-19:00',
   ];

   useEffect(() => {
      // Aktif tarihi seçip uygun saatleri görebilmek için
      handleDateChange(bookingDate)
   }, [])

   const calculateAvailableTimeSlots = (date) => {
      const today = new Date();
      const selectedDateTime = new Date(date);
      const currentTime = today.getHours();
      const selectedTime = selectedDateTime.getHours();

      console.log('currentTime', currentTime);
      console.log('selectedTime', selectedTime);

      if (selectedDateTime.getDate() === today.getDate() && selectedTime > currentTime) {
         // Eğer seçilen tarih bugünden önceki bir tarihse, tüm saat dilimlerini devre dışı bırak


      } else if (selectedDateTime.getDate() === today.getDate()) {

         // Eğer seçilen tarih bugünse, geçmiş saat dilimlerini gösterme
         setAvailableTimeSlots(
            timeSlots.filter(
               (timeSlot) =>
                  parseInt(timeSlot.split(':')[0]) >= selectedTime &&
                  parseInt(timeSlot.split(':')[0]) > currentTime
            )
         );
      } else {
         // Eğer seçilen tarih gelecekteyse, tüm saat dilimlerini göster
         setAvailableTimeSlots(timeSlots);
      }
   };

   // Bugünden önceki tarihleri devre dışı bırakacak bir işlev
   const isDateDisabled = (date) => {
      const today = new Date();
      const isPastDate = date.getDate() < today.getDate();
      const sameDays = date.getDate() === today.getDate()

      // Bugünden önceki tarihleri devre dışı bırak
      return isPastDate || (sameDays && today.getHours() >= 19);
   };

   const handleDateChange = (date) => {
      // Burada hafta sonlarını kontrol edebilir ve işlem yapabilirsiniz.
      if (date.getDay() === 0 || date.getDay() === 6) {
         Swal.fire({
            icon: 'error',
            title: `${t('appointmentPage.weekendsErrorTitle')}`,
            text: `${t('appointmentPage.weekendsErrorDesc')}`
         })
         return;
      }

      setBookingDate(date);
      calculateAvailableTimeSlots(date);
   };

   const handleApplicationChange = (e) => {
      setApplication(e.target.value);
   }

   const handleTimeSlotSelect = (timeSlot) => {
      // Seçilen saat dilimini işleme almak için bu işlevi kullanın.
      // Burada seçilen tarih ve saat dilimini kullanarak rezervasyon işlemi gerçekleştirilebilir.

      console.log(`Seçilen Tarih: ${bookingDate.toLocaleDateString()}, Seçilen Saat Dilimi: ${timeSlot}`);
      setBookingHour(timeSlot);
   };

   const postData = async (e) => {
      e.preventDefault();
      try {
         if (mail === '') {
            setFromError(`${t('appointmentPage.emailError')}`);
            return;
         } else if (name === '') {
            setFromError(`${t('appointmentPage.nameError')}`);
            return;
         }
         else if (phone === '') {
            setFromError(`${t('appointmentPage.phoneError')}`);
            return;
         } else {
            setFromError('');
         }

         const payload = {
            "to": mail,
            "name": name,
            "date": moment(bookingDate).format('LL'),
            "phone": phone,
            "hour": bookingHour,
            "application": application,
            "message": message
         }

         const response = await axios.post('https://drebrukaragunsmtpserver.vercel.app/send-email', payload);
         if (response.status === 200) {
            Swal.fire(
               `${t('appointmentPage.successful')}`,
               `${t('appointmentPage.appointmentRequest')}`,
               'success'
            )
         }
      } catch (error) {
         if (error.response.status === 429) {
            Swal.fire({
               icon: 'error',
               title: `${t('appointmentPage.error')}`,
               text: `${t('appointmentPage.tooManyRequest')}`
            })
         } else {
            Swal.fire({
               icon: 'error',
               title: `${t('appointmentPage.error')}`,
               text: `${t('appointmentPage.appointmentError')}`
            })
         }

         console.log('Error', error);
      }

   }

   return (
      <>
         <section className="appointment-area appointment-area-3 appointment_page_bg pos-rel pt-50 pb-120"
         >
            <div className="container">
               <div className="row">
                  <div className="col-xl-12 col-lg-12">
                     <div className="calculate-box white-bg">
                        <div className="calculate-content">
                           <div className="row">
                              <div className="col-xl-12">
                                 <div className="about-title news-letter-title mb-70">
                                    <h3 className="pink-color">{t('appointmentPage.makeAnAppointment')}</h3>
                                    <h1>{t('appointmentPage.onlineAppointment')}</h1>
                                 </div>
                              </div>
                           </div>
                           <div className='row mb-30' style={{ display: 'flex', justifyContent: 'center' }}>
                              <div className="col-xl-6 col-lg-6 col-md-6">
                                 <Calendar onChange={handleDateChange} value={bookingDate}
                                    tileDisabled={({ date }) => isDateDisabled(date)} />
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 mobile-margin">
                                 <h4> {t('appointmentPage.selectAppointmentTime')} </h4>
                                 <ul style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {availableTimeSlots.map((timeSlot) => (
                                       <li key={timeSlot} style={{ margin: "10px" }}>
                                          <button className={timeSlot === bookingHour ? 'primary_hover_btn mobile-hours-btn' : 'primary_hover_btn mobile-hours-btn btn-icon-success'}
                                             style={{ borderRadius: "10px" }} onClick={() => handleTimeSlotSelect(timeSlot)}>{timeSlot}</button>
                                       </li>
                                    ))}
                                 </ul>
                              </div>
                           </div>
                           <div className="row">
                              {formError && <div>
                                 <h3 className='pink-color text-center mb-20'>{formError}</h3>
                              </div>}
                              <div className="col-xl-6 col-lg-6 col-md-6">
                                 <form className="calculate-form appointment-form-3 mb-20" action="#">
                                    <input type="text" value={mail} onChange={(e) => { setMail(e.target.value) }} placeholder={`${t('mainPage.formMail')}`} />
                                    <i className="fas fa-envelope"></i>
                                 </form>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6">
                                 <form className="calculate-form appointment-form-3 mb-20" action="#">
                                    <input type="text" value={name} onChange={(e) => { setName(e.target.value) }} placeholder={`${t('mainPage.formName')}`} />
                                    <i className="fas fa-user"></i>
                                 </form>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6">
                                 <form className="calculate-form appointment-form-3 mb-20" action="#">
                                    <input type="tel" value={phone} onChange={(e) => { setPhone(e.target.value) }} pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" placeholder={`${t('mainPage.formPhone')}`} />
                                    <i className="fas fa-phone"></i>
                                 </form>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6">
                                 <form className="calculate-form appointment-form-3 mb-20" action="#">
                                    <textarea name="Special request" value={message} onChange={(e) => { setMessage(e.target.value) }} cols="30" rows="10" placeholder={`${t('mainPage.formMessage')}`}></textarea>
                                    <i className="far fa-edit"></i>
                                 </form>
                              </div>
                           </div>
                           <button onClick={postData} className="primary_btn mt-40">{t('appointmentPage.makeAnAppointment')}</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default BookAppoinment;