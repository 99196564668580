import React from 'react';
import ServicesTwoSingleItem from '../../../../components/ServicesTwoSingleItem/ServicesTwoSingleItem';
import { Helmet } from 'react-helmet-async';
import applications from '../../../../fakeData/applications/applications';
import enApplications from '../../../../fakeData/applications/en-applications';
import { useTranslation } from 'react-i18next';

const ServicesTwoArea = ({ data }) => {
   //Uygulama Kategori Sayfası
   const { t, i18n } = useTranslation();
   const metaDescription = i18n.language === 'en' ? enApplications.filter(e => e.applicationid === data[0].applicationid) : applications.filter(e => e.applicationid === data[0].applicationid)

   return (
      <>
         <section className="servcies-area gray-bg pt-115 pb-90">
            <Helmet>
               <title>{`${data[0].applicationTitle}`}</title>
               <meta name='description' content={`${metaDescription[0].description}`} />
            </Helmet>
            <div className="container">
               <div className="row">
                  <div className="col-xl-7 col-lg-8 col-md-12">
                     <div className="section-title pos-rel mb-75">
                        <div className="section-icon">
                           <img className="section-back-icon back-icon-left" src="/img/section/section-back-icon.png" alt="" />
                        </div>
                        <div className="section-text pos-rel">
                           <h2 className='sub-custom-header'>{t('applicationPage.header')}</h2>
                           <h1>{data[0].applicationTitle}</h1>
                        </div>
                        <div className="section-line pos-rel">
                           <img src="/img/shape/section-title-line.png" alt="" />
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row">
                  {
                     data.map((e, i) => {
                        return <ServicesTwoSingleItem id={e.id} image={e.image} title={e.content?.blogPageTitle} description={e.content?.blogPageDesc}
                           applicationid={e.applicationid} />
                     })
                  }
               </div>
            </div>
         </section>
      </>
   );
};

export default ServicesTwoArea;