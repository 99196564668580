import React from 'react';
import BlogSmSingleColumn from '../../../components/BlogTwoSingleColumn/BlogSmSingleColumn';
import blog from '../../../fakeData/blogs/blogs';
import enBlog from '../../../fakeData/blogs/en-blogs';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const BlogTwoArea = () => {
    //Blog Kategori Sayfası
    const { t, i18n } = useTranslation();

    return (
        <>
            <section className="blog-area pt-50 pb-120">
                <Helmet>
                    <title>{'Doç. Dr. Ebru Karagün | Blog'}</title>
                    <meta name='description' content='Doç. Dr. Ebru Karagün dolgu, ameliyatsız operasyonlar, mezoterapi, glutatyon, yüz askılama, sağlıklı yaşam bilgileri veriyor. Ankara' />
                </Helmet>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-lg-8 col-md-12">
                            <div className="section-title pos-rel mb-75">
                                <div className="section-icon">
                                    <img className="section-back-icon back-icon-left" src="/img/section/blog-background.png" width={480} style={{ opacity: 0.3 }} alt="" />
                                </div>
                                <div className="section-text pos-rel">
                                    <h2 className='sub-custom-header'>{t('blogsPage.subHeader')}</h2>
                                    <h1>{t('blogsPage.header')}</h1>
                                </div>
                                <div className="section-line pos-rel">
                                    <img src="/img/shape/section-title-line.png" alt="" />
                                </div>
                            </div>
                        </div>

                        {i18n.language === 'en' ?
                            enBlog.map((e, i) => {
                                return <BlogSmSingleColumn id={e.blogid} image={e.image} title={e.content?.blogPageTitle} decription={e.content?.blogPageDesc}
                                    date={e.date} />
                            }) :
                            blog.map((e, i) => {
                                return <BlogSmSingleColumn id={e.blogid} image={e.image} title={e.content?.blogPageTitle} decription={e.content?.blogPageDesc}
                                    date={e.date} />
                            })
                        }

                    </div>

                </div>
            </section>
        </>
    );
};

export default BlogTwoArea;