import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PostDetailsRightSide = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <div className="col-lg-4">
                <div className="widget mb-40">
                    <div className="widget-title-box mb-30">
                        <span className="animate-border"></span>
                        <h3 className="widget-title">{t('mediaDetailsPage.mostRead')}</h3>
                    </div>
                    <ul className="recent-posts">
                        <li>
                            <div className="widget-posts-image">
                                <Link to="/blogs/gunes-yanigi">
                                    <div className='custom-image-container'>
                                        <img src="/img/blog-images/gunes-yanigi.jpg" alt="" />
                                    </div>
                                </Link>
                            </div>
                            <div className="widget-posts-body">
                                <h6 className="widget-posts-title"><Link to="/blogs/gunes-yanigi">{t('mediaDetailsPage.blog1Title')}</Link></h6>
                                <div className="widget-posts-meta">{t('mediaDetailsPage.blog1Desc')} </div>
                            </div>
                        </li>
                        <li>
                            <div className="widget-posts-image">
                                <Link to="/blogs/cildiniz-icin-en-iyi-7-antidoksan">
                                    <div className='custom-image-container'>
                                        <img src="/img/blog-images/cildiniz-icin-en-iyi-7-antidoksan.jpg" alt="" />
                                    </div>
                                </Link>
                            </div>
                            <div className="widget-posts-body">
                                <h6 className="widget-posts-title"><Link to="/blogs/cildiniz-icin-en-iyi-7-antidoksan">{t('mediaDetailsPage.blog2Title')}</Link></h6>
                                <div className="widget-posts-meta">{t('mediaDetailsPage.blog2Desc')}</div>
                            </div>
                        </li>
                        <li>
                            <div className="widget-posts-image">
                                <Link to="/blogs/erkek-tipi-sac-dokulmesi">
                                    <div className='custom-image-container'>
                                        <img src="/img/blog-images/erkek-tipi-sac-dokulmesi.jpg" alt="" />
                                    </div>
                                </Link>
                            </div>
                            <div className="widget-posts-body">
                                <h6 className="widget-posts-title"><Link to="/blogs/erkek-tipi-sac-dokulmesi">{t('mediaDetailsPage.blog3Title')}</Link></h6>
                                <div className="widget-posts-meta">{t('mediaDetailsPage.blog3Desc')} </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="widget mb-40">
                    <div className="widget-title-box mb-30">
                        <span className="animate-border"></span>
                        <h3 className="widget-title">{t('applicationPage.header')}</h3>
                    </div>
                    <ul className="cat">
                        <li>
                            <Link to={i18n.language === 'en' ? "/en/applications/healthy-living" : "/uygulamalar/saglikli-yasam"}>{t('headerTwo.healthyLiving')}<span className="f-right">2</span></Link>
                        </li>
                        <li>
                            <Link to={i18n.language === 'en' ? "/en/applications/filler-applications" : "/uygulamalar/dolgu-uygulamalari"}>{t('headerTwo.fillingApplications')}<span className="f-right">9</span></Link>
                        </li>
                        <li>
                            <Link to={i18n.language === 'en' ? "/en/applications/thread-applications" : "/uygulamalar/iple-olan-uygulamalar"}>{t('headerTwo.faceLiftingApplications')} <span className="f-right">4</span></Link>
                        </li>
                        <li>
                            <Link to={i18n.language === 'en' ? "/en/applications/mesotherapy-applications" : "/uygulamalar/mezoterapi-uygulamalari"}>{t('headerTwo.mesotherapyApplications')} <span className="f-right">7</span></Link>
                        </li>
                        <li>
                            <Link to={i18n.language === 'en' ? "/en/applications/device-procedures" : "/uygulamalar/cihaz-islemleri"}>{t('headerTwo.deviceOperations')} <span className="f-right">6</span></Link>
                        </li>
                    </ul>
                </div>
                <div className="widget mb-40">
                    <div className="widget-title-box mb-30">
                        <span className="animate-border"></span>
                        <h3 className="widget-title">{t('footerTwo.socialMedia')}</h3>
                    </div>
                    <div className="social-profile">
                        <a href="https://www.facebook.com/doc.drebrukaragun" target='_blank' rel="noreferrer"><i className="fab fa-facebook-f"></i></a>
                        <a href="https://www.instagram.com/doc.drebrukaragun/" target='_blank' rel="noreferrer"><i className="fab fa-instagram"></i></a>
                        <a href="https://www.youtube.com/@ezdermdoc.dr.ebrukaragun1982" target='_blank' rel="noreferrer"><i className="fab fa-youtube"></i></a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PostDetailsRightSide;