import React from 'react';
import MediaDetailsLeftSide from '../../components/MediaDetailsLeftSide/MediaDetailsLeftSide';
import MediaDetailsRightSide from '../../components/MediaDetailsRightSide/MediaDetailsRightSide';
import { Helmet } from 'react-helmet-async';

const MediaDetailsArea = ({ postData }) => {
   return (
      <>
         <section className="blog-area pt-50 pb-80">
            <Helmet>
               <title>{`${postData[0].title}`}</title>
               <meta name='description' content={postData[0].content.metaDescription} />
            </Helmet>
            <div className="container">
               <div className="row">

                  <MediaDetailsLeftSide postData={postData[0]} details_topBar={<div className="postbox__thumb mb-35">
                     <img src={postData[0]?.image} alt="blog img" />
                  </div>} />

                  <MediaDetailsRightSide pdflink={postData[0]?.pdflink} medialink={postData[0]?.medialink}/>

               </div>
            </div>
         </section>
      </>
   );
};

export default MediaDetailsArea;