import React, {useState} from 'react';

const Accordion = ({ title, description, blogTitle }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleAccordion = () => {
        setIsExpanded(!isExpanded);
    };
  
    return (
        <div>
            <div className="accordion inner-content mb-20">
                <div className="accordion-title" onClick={toggleAccordion}>
                    <h4>{title}</h4>
                    <span className="accordion-icon">
                        <i className={`fas ${isExpanded ? 'fa-caret-up' : 'fa-caret-down'}`} style={{ color: 'black' }}></i>
                    </span>
                </div>
                {isExpanded && <p className="accordion-description" dangerouslySetInnerHTML={{ __html: description }} />}
            </div>
        </div>
    );
  };

const BlogDetailsLeftSide = ({details_topBar, blogData}) => {
    return (
        <>
            <div className="col-lg-8">
                <article className="postbox post format-image mb-40">
                    {details_topBar}
                    <div className="postbox__text bg-none">
                        <div className="post-meta mb-15">
                            {blogData && blogData.date ?  <span><i className="far fa-calendar-check"></i> {blogData && blogData.date} </span> : null}
                        </div>
                        <h3 className="blog-title">
                            {blogData && blogData.title}
                        </h3>
                        <div className="post-text mb-40">
                            {blogData && blogData.content?.blogDetailPageInfo?.map((item, index) => (
                                <div key={index}>
                                    {item.title && <h4>{item.title}</h4>}
                                    {item.description && <p dangerouslySetInnerHTML={{ __html: item.description}} />}
                                </div>
                            ))}

                            {blogData && blogData.accordeon?.map((item, index) => (
                                <Accordion key={index} title={item.title} description={item.description} blogTitle={blogData.title} />
                            ))}
                        </div>
                        
                    </div>
                </article>
            </div>
        </>
    );
};

export default BlogDetailsLeftSide;