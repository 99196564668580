import React from 'react';
import { Link } from 'react-router-dom';
import VideoPopup from '../../../components/VideoPopup/VideoPopup';
import useGlobalContext from '../../../hooks/useGlobalContext';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const HomeTwoHeroSection = () => {
   const { t, i18n } = useTranslation();
   const values = useGlobalContext();
   const { setIsOpen } = values;

   return (
      <>
         <VideoPopup videoId="j6Oq8RC5jOI" />

         <section className="hero-area">
            <Helmet>
               <title>{'Doç. Dr. Ebru Karagün'}</title>
               <meta name='description' content='Yüz dolgu uygulamaları, yüz ip askılama, badem göz, saç ve yüz mezoterapisi, glutatyon, ozon uygulaması tedavileri, Scarlet X, kaş silme, dövme silme, yüz leke tedavisi, çatlak, akne scar, sellülit tedavileri' />
            </Helmet>
            <div className="hero-slider hero_two_slider">
               <div className="slider-active">
                  <div className="single-slider slider-height slider-height-2 d-flex align-items-center" data-background="/img/slider/slider-bg-2.png" >
                     <div className="container">
                        <div className="row align-items-center">
                           <div className="col-xl-9 col-lg-9 col-md-10">
                              <div className="hero-text hero-text-2 pt-35">
                                 <div className="hero-slider-caption hero-slider-caption-2">
                                    <h2 className='custom-main-header white-color'>{t('mainPage.drTitle')}</h2>
                                    <h3 className="white-color mb-25">{t('mainPage.dermatologySpecialist')}</h3>
                                    <h1 className="custom-main-title white-color font-link" >{t('mainPage.mainHeader')}</h1>
                                 </div>
                                 <div className="hero-slider-btn">
                                    <Link to={i18n.language === 'en' ? "/en/appointment" : "/randevu-al"} className="primary_btn btn-icon btn-icon-blue ml-0"><span><i className="fas fa-calendar-check"></i></span>{t('headerTwo.appointment')}</Link>
                                    {/* <button onClick={() => setIsOpen(true)} className="play-btn popup-video"><i className="fas fa-play"></i></button> */}
                                 </div>
                              </div>
                           </div>
                           <div className='hidden-header'>

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoHeroSection;