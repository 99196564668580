import React from 'react';
import BlogDetailsLeftSide from '../../../components/BlogDetailsLeftSide/BlogDetailsLeftSide';
import BlogDetailsRightSide from '../../../components/BlogDetailsRightSide/BlogDetailsRightSide';
import { Helmet } from 'react-helmet-async';

const BlogDetailsArea = ({ blogData }) => {
   return (
      <>
         <section className="blog-area pt-50 pb-80">
            <Helmet>
               <title>{`${blogData[0].title}`}</title>
               <meta name='description' content={blogData[0].content.metaDescription} />
            </Helmet>
            <div className="container">
               <div className="row">

                  <BlogDetailsLeftSide blogData={blogData[0]} details_topBar={<div className="postbox__thumb mb-35">
                     <img src={blogData[0]?.image} alt="blog img" />
                  </div>} />

                  <BlogDetailsRightSide />

               </div>
            </div>
         </section>
      </>
   );
};

export default BlogDetailsArea;