import React from 'react';
import BlogFirstArticle from '../../Blogs/BlogsArea/BlogFirstArticle';
import media from '../../../fakeData/media/media';
import enMedia from '../../../fakeData/media/en-media';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const BlogNoSideBarArea = () => {
   //Media Kategori Sayfası
   const { t, i18n } = useTranslation();

   return (
      <>
         <section className="blog-area pt-50 pb-80">
            <Helmet>
               <title>{'Doç. Dr. Ebru Karagün | Medyada Biz'}</title>
               <meta name='description' content='Doç. Dr. Ebru Karagün Cilt Çatlakları Kendi kliniğinde hastalarını kabul eden Dermatoloji Doç. Dr. Ebru Karagün, cilt çatlaklarının oluşumunu ele alarak tedavi yöntemlerine ve çatlak oluşumunu minimalize etmenin yollarına ışık tutuyor.' />
            </Helmet>
            <div className="container">
               <div className="row">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                     <div className="section-title pos-rel mb-25">
                        <div className="section-text pos-rel">
                           <h1 className='pink-color'>{t('headerTwo.weInTheMedia')}</h1>
                        </div>
                        <div className="section-line pos-rel">
                           <img src="/img/shape/section-title-line.png" alt="" />
                        </div>
                     </div>
                  </div>

                  <div className="col-lg-8 offset-lg-2">
                     {
                        i18n.language === 'en' ?
                           enMedia.map((e, i) => {
                              return <BlogFirstArticle mediaId={e.mediaid} image={e.image} title={e.content?.blogPageTitle} decription={e.content?.blogPageDesc}
                                 date={e.date} />
                           }) :
                           media.map((e, i) => {
                              return <BlogFirstArticle mediaId={e.mediaid} image={e.image} title={e.content?.blogPageTitle} decription={e.content?.blogPageDesc}
                                 date={e.date} />
                           })
                     }

                     <div className="post-text mb-40">
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default BlogNoSideBarArea;