import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BlogDetailsRightSide = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <div className="col-lg-4">
                <div className="widget mb-40">
                    <div className="widget-title-box mb-30">
                        <span className="animate-border"></span>
                        <h3 className="widget-title">{t('appDetailPage.popularApplications')}</h3>
                    </div>
                    <ul className="recent-posts">
                        <li>
                            <div className="widget-posts-image">
                                <Link to={i18n.language === 'en' ? "/en/applications/filler-applications/filling-application" : "/uygulamalar/dolgu-uygulamalari/dolgu-uygulamasi"}>
                                    <div className='custom-image-container'>
                                        <img src="/img/applications/dolgu-uygulamalari/dolgu-uygulamasi.jpg" alt="" />
                                    </div>
                                </Link>
                            </div>
                            <div className="widget-posts-body">
                                <h6 className="widget-posts-title"><Link to={i18n.language === 'en' ? "/en/applications/filler-applications/filling-application" : "/uygulamalar/dolgu-uygulamalari/dolgu-uygulamasi"}>{t('appDetailPage.popularApp1Title')}</Link></h6>
                                <div className="widget-posts-meta">{t('appDetailPage.popularApp1Desc')} </div>
                            </div>
                        </li>
                        <li>
                            <div className="widget-posts-image">
                                <Link to={i18n.language === 'en' ? "/en/applications/device-procedures/scarlet-x" : "/uygulamalar/cihaz-islemleri/scarlet-x"}>
                                    <div className='custom-image-container'>
                                        <img src="/img/applications/cihaz-islemleri/scarlet-x.jpg" alt="" />
                                    </div>
                                </Link>
                            </div>
                            <div className="widget-posts-body">
                                <h6 className="widget-posts-title"><Link to={i18n.language === 'en' ? "/en/applications/device-procedures/scarlet-x" : "/uygulamalar/cihaz-islemleri/scarlet-x"}>{t('appDetailPage.popularApp2Title')}</Link></h6>
                                <div className="widget-posts-meta">{t('appDetailPage.popularApp2Desc')} </div>
                            </div>
                        </li>
                        <li>
                            <div className="widget-posts-image">
                                <Link to={i18n.language === 'en' ? "/en/applications/device-procedures/endolift" : "/uygulamalar/cihaz-islemleri/endolift"}>
                                    <div className='custom-image-container'>
                                        <img src="/img/applications/cihaz-islemleri/endolift.jpg" alt="" />
                                    </div>
                                </Link>
                            </div>
                            <div className="widget-posts-body">
                                <h6 className="widget-posts-title"><Link to={i18n.language === 'en' ? "/en/applications/device-procedures/endolift" : "/uygulamalar/cihaz-islemleri/endolift"}>{t('appDetailPage.popularApp3Title')}</Link></h6>
                                <div className="widget-posts-meta">{t('appDetailPage.popularApp3Desc')} </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="widget mb-40">
                    <div className="widget-title-box mb-30">
                        <span className="animate-border"></span>
                        <h3 className="widget-title">{t('applicationPage.header')}</h3>
                    </div>
                    <ul className="cat">
                        <li>
                            <Link to={i18n.language === 'en' ? "/en/applications/healthy-living" : "/uygulamalar/saglikli-yasam"}>{t('headerTwo.healthyLiving')}<span className="f-right">2</span></Link>
                        </li>
                        <li>
                            <Link to={i18n.language === 'en' ? "/en/applications/filler-applications" : "/uygulamalar/dolgu-uygulamalari"}>{t('headerTwo.fillingApplications')}<span className="f-right">9</span></Link>
                        </li>
                        <li>
                            <Link to={i18n.language === 'en' ? "/en/applications/thread-applications" : "/uygulamalar/iple-olan-uygulamalar"}>{t('headerTwo.faceLiftingApplications')} <span className="f-right">4</span></Link>
                        </li>
                        <li>
                            <Link to={i18n.language === 'en' ? "/en/applications/mesotherapy-applications" : "/uygulamalar/mezoterapi-uygulamalari"}>{t('headerTwo.mesotherapyApplications')} <span className="f-right">7</span></Link>
                        </li>
                        <li>
                            <Link to={i18n.language === 'en' ? "/en/applications/device-procedures" : "/uygulamalar/cihaz-islemleri"}>{t('headerTwo.deviceOperations')} <span className="f-right">6</span></Link>
                        </li>
                    </ul>
                </div>
                <div className="widget mb-40">
                    <div className="widget-title-box mb-30">
                        <span className="animate-border"></span>
                        <h3 className="widget-title">{t('footerTwo.socialMedia')}</h3>
                    </div>
                    <div className="social-profile">
                        <a href="https://www.facebook.com/doc.drebrukaragun" target='_blank' rel="noreferrer"><i className="fab fa-facebook-f"></i></a>
                        <a href="https://www.instagram.com/doc.drebrukaragun/" target='_blank' rel="noreferrer"><i className="fab fa-instagram"></i></a>
                        <a href="https://www.youtube.com/@ezdermdoc.dr.ebrukaragun1982" target='_blank' rel="noreferrer"><i className="fab fa-youtube"></i></a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogDetailsRightSide;